import './protected-route.scss';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { useJwt } from 'react-jwt';
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import { RoleName } from '../../enums/role.enum';
import { AcroworldJwt } from '../../models/acroworld-jwt.type';
import { Outlet } from 'react-router-dom';
import {
  Confirmation_Status_EnumType,
  GetMeQueryType,
  useGetMeQuery,
  useLoginWithRefreshTokenMutation,
  useSignupAsTeacherMutation,
} from '../../__generated___/gql';
import { useEffect, useState } from 'react';
import SetUpTeacherProfile from '../profile/set-up-teacher-profile';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Duration } from 'luxon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { useReactiveVar } from '@apollo/client';
import { setMe } from '../../reactive-vars/me';
import { useUrlQuery } from '../../hooks/use-query';

const drawerWidth = 240;

export function sleep(milliseconds: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

type ContextType = { me: GetMeQueryType['me'][0] };

export const ProtectedRoute = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const me = useReactiveVar(setMe);
  const urlQuery = useUrlQuery();
  const shouldRefetchMe = urlQuery.get('refetchMe');
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [token, setToken] = useLocalStorage('jwt-token', '');
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh-token', '');
  const [refreshButtonText, setRefrehButtonText] = useState<string>();
  const [minutesLeftText, setMinutesLeftText] = useState<string>();
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [isRefreshButtonHovered, setIsRefreshButtonHovered] =
    useState<boolean>();
  const {
    loading: isGetMeLoading,
    data: getMeData,
    error: fetchMeError,
    refetch: refetchMe,
  } = useGetMeQuery({
    fetchPolicy: 'network-only',
  });
  const { isExpired, decodedToken } = useJwt<AcroworldJwt>(token);
  const [
    loginWithRefreshTokenMutation,
    {
      data: loginWithRefreshTokenData,
      loading: isLoginWithRefreshTokenLoading,
      error: loginWithRefreshTokenError,
    },
  ] = useLoginWithRefreshTokenMutation();
  const [singupAsTeacherMutation] = useSignupAsTeacherMutation();

  console.log('location', location);
  console.log('loginWithRefreshTokenData', loginWithRefreshTokenData);

  const logOut = (redirectTo?: string) => {
    setToken('');
    setRefreshToken('');
    setMe(undefined);
    navigate(
      `/login?redirectTo=${
        redirectTo ? redirectTo : `${location.pathname}${location.search}`
      }`
    );
    window.location.reload();
  };

  const loginWithRefreshToken = async () => {
    if (refreshToken) {
      try {
        await loginWithRefreshTokenMutation({
          variables: {
            refreshToken: refreshToken,
          },
        });
      } catch (e) {
        console.log(e);
        logOut();
      }
    } else {
      logOut();
    }
  };

  const getMinutesLeftText = () => {
    return decodedToken?.exp
      ? `${Duration.fromObject({
          milliseconds: decodedToken.exp * 1000 - Date.now(),
        }).toFormat('mm')} minutes`
      : '';
  };

  useEffect(() => {
    if (shouldRefetchMe) {
      refetchMe();
      const { pathname, search } = location;
      const queryParams = new URLSearchParams(search);
      queryParams.delete('refetchMe');
      const newSearch = queryParams.toString();
      navigate(`${pathname}${newSearch ? '?' + newSearch : ''}`);
    }
  });

  useEffect(() => {
    setMinutesLeftText(getMinutesLeftText());
  }, []);

  useEffect(() => {
    setMinutesLeftText(getMinutesLeftText());
  }, [decodedToken]);

  useEffect(() => {
    console.log('getMeData', getMeData);
    if (getMeData?.me) {
      setMe(getMeData.me[0]);
    }
  }, [getMeData]);

  useEffect(() => {
    if (decodedToken) {
      console.log('decodedToken', decodedToken);
      const interval = setInterval(() => {
        if (decodedToken?.exp) {
          setMinutesLeftText(getMinutesLeftText());
          const fiveMinutesBeforeExpiry =
            decodedToken.exp * 1000 - 5 * 60 * 1000;
          if (Date.now() >= fiveMinutesBeforeExpiry) {
            console.log('Date.now() >= fiveMinutesBeforeExpiry');
            loginWithRefreshToken();
          }
        }
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [JSON.stringify(decodedToken)]);

  console.log('loginWithRefreshTokenData', loginWithRefreshTokenData);

  useEffect(() => {
    if (loginWithRefreshTokenData) {
      setToken(loginWithRefreshTokenData.loginWithRefreshToken.token);
      setRefreshToken(
        loginWithRefreshTokenData.loginWithRefreshToken.refreshToken
      );
      refetchMe();
    }
  }, [loginWithRefreshTokenData]);

  useEffect(() => {
    if (loginWithRefreshTokenError) {
      logOut();
    }
  }, [loginWithRefreshTokenError]);

  useEffect(() => {
    if (isRefreshButtonHovered) {
      setRefrehButtonText(getMinutesLeftText());
    } else {
      setRefrehButtonText(getMinutesLeftText());
    }
  }, [minutesLeftText, isRefreshButtonHovered]);

  useEffect(() => {
    if (isExpired) {
      loginWithRefreshToken();
    }
  }, [isExpired]);

  useEffect(() => {
    if (token && !isExpired) {
      refetchMe();
    } else {
      if (refreshToken) {
        loginWithRefreshToken();
      } else {
        logOut();
      }
    }
  }, [token, isExpired]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (fetchMeError) {
    if (fetchMeError.message.includes('JWTExpired')) {
      return <LoadingOverlay isOpen={true}></LoadingOverlay>;
    }
    return (
      <Box>
        <Typography>An Error occured</Typography>
      </Box>
    );
  }

  const user = getMeData?.me[0];
  if (isGetMeLoading || !decodedToken || !user) {
    return <LoadingOverlay isOpen={true}></LoadingOverlay>;
  }

  const userRoles =
    decodedToken['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];

  const isAdminUser = userRoles.some((role) => role === RoleName.AdminUser);
  const isTeacherUser = userRoles.some((role) => role === RoleName.TeacherUser);
  const isPrivelegedUser = isAdminUser || isTeacherUser;
  const mustSetUpProfile = !user?.teacher_profile && isTeacherUser;
  const imageUrl =
    user.teacher_profile?.images.find(
      (teacherImage) => teacherImage.is_profile_picture
    )?.image.url || user.image_url;
  const userName = user.teacher_profile?.name || user.name;

  return (
    <Box className="scroll-parent">
      <AppBar position="sticky">
        <Toolbar className="toolbar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Box className="center-container">
            <Box className="avatar-container">
              <Avatar src={imageUrl} alt={userName} />
              <p>{userName}</p>
            </Box>
            <Box className="button-container">
              <Button
                color="light"
                variant="outlined"
                startIcon={
                  isRefreshButtonHovered ? <RefreshIcon /> : <AccessTimeIcon />
                }
                style={{ textTransform: 'none' }}
                onClick={() => {
                  loginWithRefreshToken();
                }}
                onMouseOver={() => {
                  setIsRefreshButtonHovered(true);
                }}
                onMouseOut={() => {
                  setIsRefreshButtonHovered(false);
                }}
              >
                {refreshButtonText}
              </Button>
            </Box>
          </Box>

          <Box></Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    setOpen(false);
                    navigate('/app');
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText>Home</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
          </Box>

          <Box>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => logOut('/app')}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>

      {me?.teacher_profile?.confirmation_status ===
        Confirmation_Status_EnumType.PendingType && (
        <Collapse in={isBannerOpen}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsBannerOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Your profile is not confirmed yet. You can add/edit classes but they
            will not be be shown to users.
          </Alert>
        </Collapse>
      )}

      <LoadingOverlay isOpen={isLoginWithRefreshTokenLoading}></LoadingOverlay>
      {isPrivelegedUser ? (
        mustSetUpProfile ? (
          <SetUpTeacherProfile userId={user.id} />
        ) : (
          <Outlet context={{ me }} />
        )
      ) : (
        <Box
          style={{ height: 'calc(100vh - 64px)' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="20px"
        >
          <Alert severity="info">
            You are currently not registered as a teacher. Click the button
            below to register as teacher and join the AcroWorld teacher
            community.
          </Alert>
          <Button
            variant="contained"
            onClick={async () => {
              await singupAsTeacherMutation();
              loginWithRefreshToken();
            }}
          >
            Register as teacher
          </Button>
        </Box>
      )}
    </Box>
  );
};

export function useMe() {
  return useOutletContext<ContextType>();
}
