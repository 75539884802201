import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from '@mui/material';
import { LoadingOverlay } from '../../../components/loading-overlay/loading-overlay';
import { useEffect, useState } from 'react';
import { FileUtils } from '../../../lib/utils/file-utils';
import { useMutation, useQuery } from '@apollo/client';
import Mutations from '../../../graphql/mutations';
import { Firebase } from '../../../lib/firebase';
import { UpdateResponse } from '../../../graphql/models/responses/update-response.model';
import { useNavigate, useParams } from 'react-router-dom';
import { GetClassByPkResponse } from '../../../graphql/models/responses/get-class-by-pk-response.type';
import Queries from '../../../graphql/queries';
import { ClassView } from '../../../components/classes/class/class';
import { Class } from '../../../models/class.type';
import { useRoles } from '../../../hooks/use-roles';
import { RoleName } from '../../../enums/role.enum';
import { useMe } from '../../../components/protected-route/protected-route';
import AddIcon from '@mui/icons-material/Delete';
import ClassMutations from '../../../graphql/teachers/class-mutations';
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from '../../../components/global-notification-overlay/global-notification-overlay';
import {
  Invite_Entity_EnumType,
  useInviteMutation,
} from '../../../__generated___/gql';
import { Teacher } from '../../../models/teacher.type';
import { TeacherSlim } from '../../../models/teacher-slim.model';
import { FormError } from '../../../models/form-error.model';
import { ScrollContainer } from '../../../components/scroll-container/scroll-container';

export const EditClassForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { me } = useMe();
  const [isTeacherUser, setIsTeacherUser] = useState(false);
  const roles = useRoles();
  const [
    updateMutationFunction,
    {
      data: updateMutationData,
      loading: isUpdateMutationLoading,
      error: updateMutationError,
    },
  ] = useMutation<UpdateResponse<'insert_classes' | 'delete_classes_by_pk'>>(
    Mutations.UpdateClassMutation
  );
  const [
    deleteMutationFunction,
    {
      data: deleteMutationData,
      loading: isDeleteMutationLoading,
      error: deleteMutationError,
    },
  ] = useMutation<UpdateResponse<'delete_classes_by_pk'>>(
    ClassMutations.Delete
  );
  const {
    error: getClassError,
    loading: isGetClassByPkLoading,
    data: getClassByPkData,
  } = useQuery<GetClassByPkResponse>(Queries.GetClassByPk, {
    fetchPolicy: 'network-only',
    variables: { id: id },
  });
  const [inviteMutation] = useInviteMutation();
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageAsBase64, setImageAsBase64] = useState('');
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);

  const classTeachers = getClassByPkData?.classes_by_pk.class_teachers;

  const isClassOwner = (teacher?: TeacherSlim): boolean => {
    return !!classTeachers?.find(
      (classTeacher) => classTeacher.teacher.id === teacher?.id
    )?.is_owner;
  };

  const [value, setValue] = useState<Partial<Class>>({
    ...(isTeacherUser && {
      class_teachers: [
        {
          teacher: { ...me.teacher_profile, isFixed: true },
          is_owner: isClassOwner(me?.teacher_profile as unknown as Teacher),
        },
      ],
    }),
  });

  useEffect(() => {
    const isTeacherUser = roles.includes(RoleName.TeacherUser);
    if (isTeacherUser) {
      setIsTeacherUser(true);
    }
  }, [roles]);

  useEffect(() => {
    setIsLoading(
      isImageUploadLoading ||
        isUpdateMutationLoading ||
        isGetClassByPkLoading ||
        isDeleteMutationLoading
    );
  }, [
    isImageUploadLoading,
    isUpdateMutationLoading,
    isGetClassByPkLoading,
    isDeleteMutationLoading,
  ]);

  useEffect(() => {
    console.log('updateMutationError', updateMutationError);
    setGlobalErrorNotification(
      updateMutationError?.message ||
        getClassError?.message ||
        deleteMutationError?.message ||
        ''
    );
  }, [updateMutationError, getClassError, deleteMutationError]);

  useEffect(() => {
    if (updateMutationData || deleteMutationData) {
      navigate('/app/classes');
      setGlobalSuccessNotification('Success!');
    }
  }, [navigate, updateMutationData, deleteMutationData]);

  useEffect(() => {
    if (getClassByPkData) {
      const newValue = {
        ...getClassByPkData.classes_by_pk,
        class_teachers: getClassByPkData.classes_by_pk.class_teachers.map(
          (classTeacher) =>
            isTeacherUser && classTeacher.teacher.id === me.teacher_profile?.id
              ? {
                  teacher: { ...classTeacher.teacher, isFixed: true },
                  is_owner: isClassOwner(classTeacher?.teacher),
                }
              : classTeacher
        ),
      };
      setValue(newValue);
    }
  }, [getClassByPkData]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={isLoading} />
      <Dialog
        open={isDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete "{value.name}"?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this event? All occurences belonging
            to this event will be deleted aswell. This action cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" justifyContent="space-between">
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                deleteMutationFunction({ variables: { id: value.id } });
              }}
              variant="contained"
              color="error"
              autoFocus
            >
              Yes, delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1>Edit {value.name ? `"${value.name}"` : ''}</h1>
        <Fab
          onClick={() => {
            setIsDeleteDialogOpen(true);
          }}
          variant="extended"
          color="error"
          aria-label="add"
        >
          <AddIcon />
          Delete
        </Fab>
      </Box>
      <Box className="inputs-container">
        {!isLoading && getClassByPkData && (
          <ClassView
            value={{
              ...getClassByPkData.classes_by_pk,
              class_teachers: getClassByPkData.classes_by_pk.class_teachers.map(
                (classTeacher) =>
                  isTeacherUser &&
                  classTeacher.teacher.id === me.teacher_profile?.id
                    ? {
                        teacher: { ...classTeacher.teacher, isFixed: true },
                        is_owner: isClassOwner(classTeacher?.teacher),
                      }
                    : classTeacher
              ),
            }}
            onNewImageSet={(imageAsBase64: string) => {
              setImageAsBase64(imageAsBase64);
            }}
            onClassUpdated={(newValue: Partial<Class>) => {
              console.log('newValue', newValue);
              setValue({
                ...value,
                ...newValue,
              });
            }}
            addFormError={(key: string, message: string) => {
              setFormErrors([...formErrors, { key, message }]);
            }}
            removeFormError={(key: string) => {
              const index = formErrors.findIndex((error) => error.key === key);
              if (index > -1) {
                formErrors.splice(index, 1);
                setFormErrors([...formErrors]);
              }
            }}
          ></ClassView>
        )}
        <Button
          variant="contained"
          onClick={async () => {
            try {
              const updateValue = {
                ...value,
                class_levels: value?.class_levels?.map((classLevel) => {
                  return { level_id: classLevel.level.id };
                }),
                class_owners: value?.class_owners?.map((classOwner) => {
                  return {
                    teacher_id: classOwner.teacher.id,
                    is_payment_receiver: classOwner.is_payment_receiver,
                  };
                }),
                class_teachers: value?.class_teachers?.map((classTeacher) => {
                  return {
                    teacher_id: classTeacher.teacher.id,
                  };
                }),
                class_booking_options: value?.class_booking_options
                  ?.filter(
                    (classBookingOption) =>
                      !classBookingOption.booking_option.wasMarkedForDeletion
                  )
                  .map((classBookingOption) => {
                    const bookingOption = classBookingOption.booking_option;
                    return {
                      booking_option: {
                        data: {
                          id: bookingOption.id,
                          title: bookingOption.title,
                          subtitle: bookingOption.subtitle,
                          price: bookingOption.price,
                          currency: bookingOption.currency,
                          discount: bookingOption.discount,
                        },
                      },
                    };
                  }),
                recurring_patterns: value?.recurring_patterns
                  ?.filter((pattern) => !pattern.wasMarkedForDeletion)
                  ?.map((recurringPattern) => {
                    return {
                      id: recurringPattern.id,
                      is_recurring: recurringPattern.is_recurring,
                      recurring_every_x_weeks:
                        recurringPattern.recurring_every_x_weeks,
                      created_at: recurringPattern.created_at,
                      day_of_week: recurringPattern.day_of_week,
                      start_date: recurringPattern.start_date,
                      start_time:
                        typeof recurringPattern.start_time === 'string'
                          ? recurringPattern.start_time
                          : recurringPattern.start_time.toISOTime(),
                      end_date: recurringPattern.end_date,
                      end_time:
                        typeof recurringPattern.end_time === 'string'
                          ? recurringPattern.end_time
                          : recurringPattern.end_time.toISOTime(),
                    };
                  }),
              };
              if (!value?.image_url && !imageAsBase64) {
                setGlobalErrorNotification('Set a picture');
                return;
              }
              if (!value?.name) {
                setGlobalErrorNotification('Set a name');
                return;
              }
              if (!value?.description) {
                setGlobalErrorNotification('Set a description');
                return;
              }
              if (!value?.location_name) {
                setGlobalErrorNotification('Provide a location name');
                return;
              }
              if (formErrors.length > 0) {
                setGlobalErrorNotification(formErrors[0].message);
                return;
              }
              if (imageAsBase64 && FileUtils.isBase64(imageAsBase64)) {
                setIsImageUploadLoading(true);
                const imageAsBlob = await FileUtils.convertBase64ToBlob(
                  imageAsBase64
                );
                const uploadedImageUrl = await Firebase.uploadFile(imageAsBlob);
                setIsImageUploadLoading(false);

                updateValue.image_url = uploadedImageUrl;
              }

              await updateMutationFunction({ variables: updateValue });
              console.log('value?.invites', value?.invites);
              if (value?.invites?.length && value?.invites?.length > 0) {
                for (const invite of value.invites) {
                  if (invite.id) {
                    // If the invite is already created
                    continue;
                  }
                  await inviteMutation({
                    variables: {
                      ...(invite?.email && { email: invite.email }),
                      ...(invite.invited_user?.id && {
                        userId: invite.invited_user?.id,
                      }),
                      entityId: id,
                      entityType: invite.entity,
                    },
                  });
                  setGlobalSuccessNotification(
                    `Invited ${
                      invite?.email ??
                      invite.invited_user?.teacher_profile?.name
                    }`
                  );
                }
              }
            } catch (e) {
              console.error(e);
              setGlobalErrorNotification(JSON.stringify(e));
            }
          }}
        >
          Update
        </Button>
      </Box>
    </ScrollContainer>
  );
};
