import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Class } from '../../../models/class.type';
import { RecurringPatternModel } from '../../../models/recurring-pattern.model';
import AddressSearch from '../../adress-search/address-search';
import LevelDropdown from '../../level-dropdown/level-dropdown';
import { Level } from '../../../models/level.type';
import { setIsGlobaLoadingVisible } from '../../global-loading-overlay/global-loading-overlay';
import ImageInput from '../../image-input/image-input';
import { TeacherSlim } from '../../../models/teacher-slim.model';
import {
  useDeleteInviteByPkMutation,
  useIsClassSlugAvailableLazyQueryType,
} from '../../../__generated___/gql';
import { InviteModel } from '../../../models/invite.model';
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from '../../global-notification-overlay/global-notification-overlay';
import tz_lookup from 'tz-lookup';
import { RecurringPattern } from '../recurring-patterns/recurring-pattern/recurring-pattern';
import { EditRecurringPatternDialog } from '../recurring-patterns/edit-recurring-pattern-dialog/edit-recurring-pattern-dialog';
import { useGetClassSlugSuggestionLazyQueryType } from '../../../__generated___/gql';
import UrlSlugInput from '../../url-slug-input/url-slug-input';
import { ClassFormField } from '../enums/class-form-fields.enum';
import { BookingOptionModel } from '../../../models/booking-option.model';
import { useMe } from '../../protected-route/protected-route';
import { EditBookingOptionDialog } from '../booking-options/edit-booking-option-dialog/edit-booking-option-dialog';
import { BookingOption } from '../booking-options/booking-option/booking-option';
import { IntegerNumberInput } from '../../integer-number-input/integer-number-input';
import { Invites } from '../../invites/invites';
import { TeacherOption } from '../../teachers/teacher-option/teacher-option';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const typeConfig: { [key: string]: { description: string } } = {
  FestivalsAndCons: {
    description:
      'A large-scale event featuring multiple acroyoga sessions, performances, and community gatherings, usually lasting several days.',
  },
  Classes: {
    description:
      'A regular, structured session where participants learn and practice acroyoga techniques, suitable for all levels.',
  },
  Workshops: {
    description:
      'A focused session led by an expert, aimed at teaching specific acroyoga techniques or concepts in a short period.',
  },
  Jams: {
    description:
      'An informal gathering where acroyoga practitioners share skills, practice together, and learn from each other without a set structure.',
  },
  Retreats: {
    description:
      'A multi-day event that combines acroyoga sessions with other activities like meditation and wellness, in a relaxing environment away from daily life.s',
  },
  Trainings: {
    description:
      "An intensive program designed to deepen participants' acroyoga practice or prepare them to teach, spanning several days to weeks.",
  },
};

interface ClassProps {
  value: Partial<Class> | undefined;
  addFormError: (key: string, message: string) => void;
  removeFormError: (key: string) => void;
  onNewImageSet: (imageAsBase64: string) => void;
  onClassUpdated: (clazz: Partial<Class>) => void;
}

export const ClassView = ({
  value,
  addFormError,
  removeFormError,
  onNewImageSet,
  onClassUpdated,
}: ClassProps) => {
  const { me } = useMe();
  console.log('ClassView:me', me);
  const isEdit = value?.id;
  const [
    deleteInviteMutation,
    { data: deleteInviteMutationData, error: deleteInviteMutationError },
  ] = useDeleteInviteByPkMutation();

  const defaultRecurringPatten = {
    start_time: DateTime.now()
      .set({
        second: 0,
        millisecond: 0,
      })
      .toUTC(),
    end_time: DateTime.now()
      .set({
        second: 0,
        millisecond: 0,
      })
      .toUTC(),
    day_of_week: 1,
    recurring_every_x_weeks: 1,
    is_recurring: true,
    created_at: DateTime.now(),
  };

  const defaultBookingOption: BookingOptionModel = {
    title: '',
    currency: 'EUR',
    discount: 0,
  };

  const [classTeachers, setClassTeachers] = useState<
    { teacher: TeacherSlim }[]
  >(value?.class_teachers || []);

  const [classOwners, setClassOwners] = useState<
    { teacher: TeacherSlim; is_payment_receiver: boolean }[]
  >(value?.class_owners ?? []);

  const [imageSource, setImageSource] = useState(value?.image_url);
  const [longitudeInput, setLongitudeInput] = useState<string>(
    value?.location?.coordinates[0].toString() || ''
  );
  const [latitudeInput, setLatitudeInput] = useState<string>(
    value?.location?.coordinates[1].toString() || ''
  );
  const [nameInput, setNameInput] = useState(value?.name || '');

  const [descriptionInput, setDescriptionInput] = useState(
    value?.description || ''
  );
  const [locationNameInput, setLocationNameInput] = useState(
    value?.location_name || ''
  );
  const [locationCityInput, setLocationCityInput] = useState(
    value?.location_city || ''
  );
  const [locationCountryInput, setLocationCountryInput] = useState(
    value?.location_country || ''
  );

  const [recurringPatterns, setRecurringPatterns] = useState<
    RecurringPatternModel[]
  >(value?.recurring_patterns ? value.recurring_patterns : []);
  const [bookingOptions, setBookingOptions] = useState<BookingOptionModel[]>(
    value?.class_booking_options
      ? value?.class_booking_options?.map(
          (classBookingOption) => classBookingOption.booking_option
        )
      : []
  );
  const [levels, setLevels] = useState<Level[]>(
    value?.class_levels
      ? [...value.class_levels?.map((classLevel) => classLevel.level)]
      : []
  );
  const [invites, setInvites] = useState<InviteModel[]>(value?.invites || []);
  const [timezone, setTimezone] = useState(value?.timezone);
  const [patternToEdit, setPatternToEdit] = useState<{
    pattern: RecurringPatternModel;
    index?: number;
  }>();
  const [maxBookingSlotsInput, setMaxBookingSlot] = useState(
    value?.max_booking_slots
  );
  const [bookingOptionToEdit, setBookingOptionToEdit] = useState<{
    option: BookingOptionModel;
    index?: number;
  }>();
  const [isUrlSlugAvailableQuery] = useIsClassSlugAvailableLazyQueryType({
    fetchPolicy: 'network-only',
  });
  const [getSlugSuggestionQuery] = useGetClassSlugSuggestionLazyQueryType({
    fetchPolicy: 'network-only',
  });
  const [urlSlugInput, setUrlSlugInput] = useState(value?.url_slug);

  const initialPaymentReceiverId = classOwners.find(
    (classOwner) => classOwner.is_payment_receiver
  )?.teacher?.id;
  const [paymentReceiverId, setPaymentReceiverId] = useState(
    initialPaymentReceiverId
  );

  const [selectedEventType, setSelectedEventType] = useState(
    value?.event_type ?? 'FestivalsAndCons'
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEventType(event.target.value);
  };

  const initialAdminsWithStripeEnabled = classOwners.filter(
    (classOwner) => classOwner.teacher?.is_stripe_enabled
  );

  const [adminsWithStripeEnable, setAdminsWithStripeEnabled] = useState(
    initialAdminsWithStripeEnabled
  );

  const [
    atLeastOneAdminWithStripeEnabled,
    setAtLeastOneAdminWithStripeEnabled,
  ] = useState(initialAdminsWithStripeEnabled.length > 0);

  useEffect(() => {
    const newPaymentReceiverId = classOwners.find(
      (classOwner) => classOwner.is_payment_receiver
    )?.teacher?.id;
    setPaymentReceiverId(newPaymentReceiverId);

    const classTeacherWithStripeEnabled = classOwners.filter(
      (classTeacher) => classTeacher.teacher?.is_stripe_enabled
    );
    setAdminsWithStripeEnabled(classTeacherWithStripeEnabled);
    setAtLeastOneAdminWithStripeEnabled(
      classTeacherWithStripeEnabled.length > 0
    );
  }, [classOwners]);

  const callGetSlugSuggestion = async () => {
    if (urlSlugInput) {
      return;
    }
    setIsGlobaLoadingVisible({ isOpen: true, text: 'Loading suggestion' });
    const { data } = await getSlugSuggestionQuery({
      variables: { name: nameInput as string },
    });
    if (data?.get_class_slug_suggestion) {
      setUrlSlugInput(data.get_class_slug_suggestion);
    }
    setIsGlobaLoadingVisible({ isOpen: false });
  };

  useEffect(() => {
    if (value?.name) {
      callGetSlugSuggestion();
    }
  }, []);

  useEffect(() => {
    console.log('ClassView:me', me);
    if (me?.teacher_profile) {
      if (!isEdit) {
        setClassOwners([
          {
            teacher: me?.teacher_profile,
            is_payment_receiver: true,
          },
        ]);
      }
    }
  }, [me]);

  useEffect(() => {
    const longitude = parseFloat(longitudeInput);
    const latitude = parseFloat(latitudeInput);
    let timezone = undefined;
    if (longitudeInput && latitudeInput) {
      timezone = tz_lookup(latitude, longitude);
      setTimezone(timezone);
    }

    onClassUpdated({
      id: value?.id,
      name: nameInput,
      location_city: locationCityInput,
      location_country: locationCountryInput,
      description: descriptionInput,
      location:
        !Number.isNaN(longitude) && !Number.isNaN(latitude)
          ? {
              type: 'Point',
              coordinates: [longitude, latitude],
            }
          : undefined,
      image_url: imageSource,
      location_name: locationNameInput,
      recurring_patterns: recurringPatterns,
      class_levels: levels.map((level) => ({
        level: level,
      })),
      invites: invites,
      timezone: timezone,
      url_slug: urlSlugInput,
      class_booking_options: bookingOptions.map((bookingOption) => ({
        booking_option: bookingOption,
      })),
      max_booking_slots: maxBookingSlotsInput,
      class_teachers: classTeachers,
      class_owners: classOwners,
      event_type: selectedEventType,
    });
  }, [
    longitudeInput,
    latitudeInput,
    nameInput,
    descriptionInput,
    locationNameInput,
    locationCityInput,
    locationCountryInput,
    recurringPatterns,
    levels,
    invites,
    timezone,
    urlSlugInput,
    bookingOptions,
    maxBookingSlotsInput,
    classOwners,
    classTeachers,
    selectedEventType,
  ]);

  useEffect(() => {
    if (imageSource) {
      onNewImageSet(imageSource);
    }
  }, [imageSource]);

  useEffect(() => {
    if (deleteInviteMutationData) {
      setGlobalSuccessNotification('Deleted invite');
      const index = invites.findIndex(
        (invite) =>
          invite.id === deleteInviteMutationData?.delete_invites_by_pk?.id
      );
      invites.splice(index, 1);
      setInvites([...invites]);
    }
  }, [deleteInviteMutationData]);

  useEffect(() => {
    if (deleteInviteMutationError) {
      setGlobalErrorNotification(deleteInviteMutationError?.message);
    }
  }, [deleteInviteMutationError]);

  console.log('ClassView:invites', invites);

  return (
    <Grid container spacing={1}>
      <h3>Image</h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} position="relative">
          <Box className="profile-image-wrapper">
            <ImageInput
              sources={imageSource ? [imageSource] : []}
              onImagesLoaded={(sources: string[]) => {
                setIsGlobaLoadingVisible({ isOpen: false });
                setImageSource(sources[0]);
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <h3>General information</h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Event Type</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={selectedEventType}
              label="Select Event Type"
              onChange={handleChange}
            >
              <MenuItem value="FestivalsAndCons">Festivals and Cons</MenuItem>
              <MenuItem value="Trainings">Trainings</MenuItem>
              <MenuItem value="Retreats">Retreats</MenuItem>
              <MenuItem value="Workshops">Workshops</MenuItem>
              <MenuItem value="Classes">Classes</MenuItem>
              <MenuItem value="Jams">Jams</MenuItem>
            </Select>
            <FormHelperText id="component-error-text">
              Currently type "{selectedEventType}" is selected.{' '}
              {typeConfig?.[selectedEventType]?.description}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            value={nameInput}
            onBlur={() => {
              callGetSlugSuggestion();
            }}
            onChange={(event) => {
              setNameInput(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <UrlSlugInput
            required={true}
            urlSlug={urlSlugInput}
            onUrlSlugUpdate={(urlSlug: string) => {
              setUrlSlugInput(urlSlug);
            }}
            hint={`In the future we will use the url slug in the url to your event. (e.g. acroworld.de/events/${
              urlSlugInput ? urlSlugInput : '<your-url-slug>'
            })`}
            isUrlSlugAvailable={async (urlSlug: string) => {
              const queryResult = await isUrlSlugAvailableQuery({
                variables: {
                  urlSlug,
                },
              });
              const isAvailable =
                (urlSlug !== '' && queryResult.data?.is_class_slug_available) ??
                false;
              if (!isAvailable) {
                addFormError(
                  ClassFormField.UrlSlug,
                  'Url slug is not available'
                );
              } else {
                removeFormError(ClassFormField.UrlSlug);
              }
              return isAvailable;
            }}
          ></UrlSlugInput>
        </Grid>

        <Grid item xs={12} sm={12}>
          <h4>Description</h4>
          <p>Please provide a description for your event</p>
        </Grid>

        <Grid item xs={12} sm={12}>
          <CKEditor
            editor={ClassicEditor}
            data={descriptionInput}
            config={{
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'underline',
                '|',
                'link',
                'numberedList',
                'bulletedList',
                '|',
                'undo',
                'redo',
              ],
            }}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', editor);
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '25em',
                  editor.editing.view.document.getRoot() as any
                );
              });
            }}
            onChange={(_, editor) => setDescriptionInput(editor.getData())}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LevelDropdown
            value={levels}
            onChange={(levelIds) => {
              setLevels(levelIds);
            }}
          />
        </Grid>
      </Grid>
      <h4>Teachers</h4>
      <Grid item xs={12} sm={12}>
        <p>Teachers are the people who are teaching at this event.</p>
      </Grid>

      <FormControlLabel
        control={
          <Switch
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const checked = event.target.checked;
              if (checked) {
                setClassTeachers([
                  { teacher: me.teacher_profile! },
                  ...classTeachers,
                ]);
              } else {
                const index = classTeachers.findIndex(
                  (classTeacher) =>
                    classTeacher?.teacher?.id === me?.teacher_profile?.id
                );

                if (index > -1) {
                  classTeachers.splice(index, 1);
                  setClassTeachers([...classTeachers]);
                }
              }
            }}
            checked={
              classTeachers.findIndex(
                (classTeacher) =>
                  classTeacher.teacher?.id === me.teacher_profile?.id
              ) > -1
            }
          />
        }
        label="I also teach at this event"
      />

      <Grid item xs={12} display="flex" gap="5px" alignItems="center">
        {classTeachers.map((classTeacher, index) => {
          return (
            <TeacherOption
              key={`class-teacher-${index}`}
              teacher={classTeacher.teacher}
              onDelete={() => {
                setClassTeachers([
                  ...classTeachers.filter(
                    (existingClassTeacher) =>
                      existingClassTeacher.teacher?.id !==
                      classTeacher.teacher?.id
                  ),
                ]);
              }}
            ></TeacherOption>
          );
        })}
        <Invites
          existingInvites={invites.filter(
            (invite) => invite.entity === 'class_teacher'
          )}
          buttonLabel={'Invite teacher'}
          getTooltipTitle={(invite) => {
            return invite?.id
              ? 'Invite was sent successfully'
              : `Invite is pending. It will be sent out when you ${
                  value?.id ? 'update' : 'create'
                } the class.`;
          }}
          onInvitesUpdate={(invitesUpdate) =>
            setInvites([
              ...invites.filter((invite) => invite.entity !== 'class_teacher'),
              ...invitesUpdate.map((invite) => ({
                ...invite,
                entity: 'class_teacher',
              })),
            ])
          }
        />
      </Grid>
      <h3>Time information</h3>

      <Grid item xs={12}>
        <p>
          Here you can the information about how often your class takes place.
        </p>
        {patternToEdit && (
          <EditRecurringPatternDialog
            pattern={patternToEdit.pattern}
            onSave={(pattern: RecurringPatternModel) => {
              if (patternToEdit.index !== undefined) {
                const recurringPatternsCopy = [...recurringPatterns];
                recurringPatternsCopy[patternToEdit.index] = {
                  ...pattern,
                  wasUpdated: true,
                };
                setRecurringPatterns(recurringPatternsCopy);
              } else {
                setRecurringPatterns([...recurringPatterns, pattern]);
              }
              setPatternToEdit(undefined);
            }}
            onCancel={() => {
              setPatternToEdit(undefined);
            }}
          />
        )}
      </Grid>

      <Grid container spacing={1}>
        {recurringPatterns
          .filter((pattern) => {
            return !pattern?.end_date || pattern?.end_date > DateTime.now();
          })
          .map((pattern, index) => {
            return (
              <Grid key={`pattern-${index}`} item xs={12} sm={12}>
                <RecurringPattern
                  pattern={pattern}
                  onEditButtonClick={() => {
                    setPatternToEdit({ pattern, index });
                  }}
                  onDeleteButtonClick={() => {
                    const recurringPatternsCopy = [...recurringPatterns];
                    if (pattern?.id) {
                      recurringPatternsCopy[index] = {
                        ...pattern,
                        wasMarkedForDeletion: true,
                      };
                    } else {
                      recurringPatternsCopy.splice(index, 1);
                    }
                    setRecurringPatterns(recurringPatternsCopy);
                  }}
                />
              </Grid>
            );
          })}
        <Grid item xs={12} sm={12} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            component="label"
            onClick={() => {
              setPatternToEdit({ pattern: defaultRecurringPatten });
            }}
          >
            Add occurence/s
          </Button>
        </Grid>
      </Grid>

      <h3>Booking information</h3>
      <Grid item xs={12}>
        <p>
          AcroWorld enables AcroYoga teachers to offer booking options,
          streamlining scheduling and payments through Stripe. Join us to
          enhance your teaching experience and broaden your reach effortlessly.
        </p>
      </Grid>

      {!atLeastOneAdminWithStripeEnabled && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Alert severity="info">
            You don't have Stripe set up and enabled yet. Please go to{' '}
            <a href={`/app/teachers/${me?.teacher_profile?.id}`}>
              your profile
            </a>{' '}
            to set Stripe for your account.
          </Alert>
        </Grid>
      )}

      {atLeastOneAdminWithStripeEnabled && (
        <Grid item xs={12}>
          <h4>Booking options</h4>
          <p>
            In this section, you can add and edit booking options for this
            class.
          </p>
          {!paymentReceiverId && (
            <Grid item xs={12}>
              <Alert severity="info">
                Please choose a payment receiver owner to add booking options
              </Alert>
            </Grid>
          )}
          {paymentReceiverId && (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography>
                  <span className="bold">
                    How many spaces of this class should be bookable via
                    AcroWorld?
                  </span>{' '}
                  This is a total amount for each class occurence and is shared
                  between the booking options.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FormControl>
                  <IntegerNumberInput
                    placeholder="Type a number…"
                    value={maxBookingSlotsInput}
                    onChange={(_, val) => setMaxBookingSlot(val)}
                  />
                </FormControl>
              </Grid>

              {isEdit && maxBookingSlotsInput !== value?.max_booking_slots && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Alert severity="info">
                    Changing this will only affect occurences of this beginning
                    from now on and not affect any existing bookings.
                  </Alert>
                </Grid>
              )}

              {bookingOptions.map((option, index) => {
                return (
                  <Grid key={`pattern-${index}`} item xs={12}>
                    <BookingOption
                      option={option}
                      onEditButtonClick={() => {
                        setBookingOptionToEdit({
                          option,
                          index,
                        });
                      }}
                      onDeleteButtonClick={() => {
                        if (option?.id) {
                          bookingOptions[index] = {
                            ...option,
                            wasMarkedForDeletion: true,
                          };
                        } else {
                          bookingOptions.splice(index, 1);
                        }
                        setBookingOptions([...bookingOptions]);
                      }}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  component="label"
                  onClick={() => {
                    setBookingOptionToEdit({
                      option: defaultBookingOption,
                    });
                  }}
                >
                  Add booking option
                </Button>
              </Grid>
            </Grid>
          )}

          {bookingOptionToEdit && (
            <EditBookingOptionDialog
              option={bookingOptionToEdit.option}
              onSave={(option: BookingOptionModel) => {
                if (bookingOptionToEdit.index !== undefined) {
                  bookingOptions[bookingOptionToEdit.index] = {
                    ...option,
                    wasUpdated: true,
                  };
                  setBookingOptions([...bookingOptions]);
                } else {
                  setBookingOptions([...bookingOptions, option]);
                }
                setBookingOptionToEdit(undefined);
              }}
              onCancel={() => {
                setBookingOptionToEdit(undefined);
              }}
            />
          )}
        </Grid>
      )}

      <h3>Location information</h3>
      <Grid item xs={12} sm={12}>
        <p>
          Location Name (e.g. name of the studio). We show this information in
          the app.
        </p>
        <TextField
          label="Location Name"
          type="text"
          variant="outlined"
          required
          fullWidth
          value={locationNameInput}
          onChange={(event) => setLocationNameInput(event.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <p>
          And please provide us with the exact location. This makes it easier
          for users of AcroWorld to find your class by its location
        </p>
        <AddressSearch
          value={
            longitudeInput && latitudeInput
              ? {
                  coordinates: {
                    longitude: parseFloat(longitudeInput),
                    latitude: parseFloat(latitudeInput),
                  },
                  city: locationCityInput,
                }
              : undefined
          }
          onPlaceUpdate={(place) => {
            setLongitudeInput(place.coordinates.longitude.toString());
            setLatitudeInput(place.coordinates.latitude.toString());
            setLocationCityInput(place.city ?? '');
            setLocationCountryInput(place.country ?? '');
          }}
        />
      </Grid>
    </Grid>
  );
};
