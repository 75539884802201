import { gql } from '@apollo/client';

export default class Mutations {
  public static Login = gql`
    mutation Login($email: String!, $password: String!) {
      login(input: { email: $email, password: $password }) {
        token
        refreshToken
      }
    }
  `;

  public static InsertClassMutation = gql`
    mutation InsertClassMutation(
      $name: String!
      $description: String!
      $image_url: String!
      $class_pass_url: String
      $usc_url: String
      $website_url: String
      $pricing: String
      $event_type: event_type_enum!
      $requirements: String
      $location: geography!
      $location_name: String!
      $location_city: String!
      $location_country: String!
      $is_classe: Boolean!
      $timezone: String!
      $url_slug: String!
      $max_booking_slots: Int
      $class_owners: [class_owners_insert_input!]!
      $class_booking_options: [class_booking_option_insert_input!]!
      $recurring_patterns: [recurring_patterns_insert_input!]!
      $class_teachers: [class_teachers_insert_input!]!
      $class_levels: [class_levels_insert_input!]!
    ) {
      insert_classes_one(
        object: {
          name: $name
          description: $description
          image_url: $image_url
          class_pass_url: $class_pass_url
          usc_url: $usc_url
          event_type: $event_type
          website_url: $website_url
          pricing: $pricing
          requirements: $requirements
          location: $location
          location_name: $location_name
          location_city: $location_city
          location_country: $location_country
          is_classe: $is_classe
          timezone: $timezone
          url_slug: $url_slug
          max_booking_slots: $max_booking_slots
          recurring_patterns: { data: $recurring_patterns }
          class_teachers: { data: $class_teachers }
          class_owners: { data: $class_owners }
          class_levels: { data: $class_levels }
          class_booking_options: { data: $class_booking_options }
        }
      ) {
        id
      }
    }
  `;

  public static UpdateClassMutation = gql`
    mutation UpdateClassMutation(
      $id: uuid!
      $name: String!
      $city: String!
      $description: String!
      $image_url: String!
      $location: geography!
      $location_name: String!
      $location_city: String!
      $location_country: String!
      $event_type: event_type_enum!
      $is_classe: Boolean!
      $timezone: String!
      $url_slug: String!
      $class_pass_url: String
      $usc_url: String
      $website_url: String
      $pricing: String
      $requirements: String
      $max_booking_slots: Int
      $class_booking_options: [class_booking_option_insert_input!]!
      $recurring_patterns: [recurring_patterns_insert_input!]!
      $class_teachers: [class_teachers_insert_input!]!
      $class_owners: [class_owners_insert_input!]!
      $class_levels: [class_levels_insert_input!]!
    ) {
      delete_booking_option(
        where: { class_booking_options: { class_id: { _eq: $id } } }
      ) {
        affected_rows
      }
      delete_classes_by_pk(id: $id) {
        id
      }
      insert_classes(
        objects: {
          id: $id
          name: $name
          city: $city
          description: $description
          event_type: $event_type
          image_url: $image_url
          class_pass_url: $class_pass_url
          usc_url: $usc_url
          is_classe: $is_classe
          website_url: $website_url
          pricing: $pricing
          requirements: $requirements
          location: $location
          location_name: $location_name
          location_city: $location_city
          location_country: $location_country
          timezone: $timezone
          url_slug: $url_slug
          max_booking_slots: $max_booking_slots
          recurring_patterns: { data: $recurring_patterns }
          class_teachers: { data: $class_teachers }
          class_owners: { data: $class_owners }
          class_levels: { data: $class_levels }
          class_booking_options: { data: $class_booking_options }
        }
      ) {
        affected_rows
        returning {
          id
          city
          name
          pricing
          location_name
          location
          image_url
          description
          class_pass_url
          requirements
          usc_url
          website_url
          is_classe
          timezone
          url_slug
          class_teachers {
            teacher {
              id
              name
            }
          }
          class_levels {
            level {
              id
              name
            }
          }
          recurring_patterns {
            id
            start_time
            end_time
            day_of_week
            created_at
            class_id
          }
        }
      }
    }
  `;
}
