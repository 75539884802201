import './create-class-form.scss';
import { Box, Button } from '@mui/material';
import { LoadingOverlay } from '../../../components/loading-overlay/loading-overlay';
import { useEffect, useState } from 'react';
import { FileUtils } from '../../../lib/utils/file-utils';
import { useMutation, useReactiveVar } from '@apollo/client';
import Mutations from '../../../graphql/mutations';
import { Firebase } from '../../../lib/firebase';
import { ClassView } from '../../../components/classes/class/class';
import { Class } from '../../../models/class.type';
import { useNavigate } from 'react-router-dom';
import { useRoles } from '../../../hooks/use-roles';
import { RoleName } from '../../../enums/role.enum';
import {
  setGlobalErrorNotification,
  setGlobalSuccessNotification,
} from '../../../components/global-notification-overlay/global-notification-overlay';
import { setMe } from '../../../reactive-vars/me';
import { useInviteMutation } from '../../../__generated___/gql';
import { FormError } from '../../../models/form-error.model';
import { ScrollContainer } from '../../../components/scroll-container/scroll-container';

export const CreateClassForm = () => {
  const navigate = useNavigate();
  const [
    mutateFunction,
    { data, loading: isInsertMutationLoading, error: insertMutationError },
  ] = useMutation<{
    insert_classes_one: {
      id: string;
    };
  }>(Mutations.InsertClassMutation);
  const [inviteMutation] = useInviteMutation();
  const me = useReactiveVar(setMe);
  const roles = useRoles();
  const [isTeacherUser, setIsTeacherUser] = useState(false);
  const [value, setValue] = useState<Partial<Class>>({
    ...(isTeacherUser &&
      me?.teacher_profile && {
        class_teachers: [
          { teacher: { ...me?.teacher_profile, isFixed: true } },
        ],
        is_classe: true,
      }),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imageSource, setImageSource] = useState('');
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  useEffect(() => {
    setIsLoading(isImageUploadLoading || isInsertMutationLoading);
  }, [isImageUploadLoading, isInsertMutationLoading]);

  useEffect(() => {
    if (insertMutationError) {
      console.log(
        'setGlobalErrorNotification',
        JSON.stringify(insertMutationError)
      );
      setGlobalErrorNotification(JSON.stringify(insertMutationError));
    }
  }, [insertMutationError]);

  useEffect(() => {
    if (data) {
      navigate('/app/classes');
      setGlobalSuccessNotification('Success! Created class');
    }
  }, [data]);

  useEffect(() => {
    const isTeacherUser = roles.includes(RoleName.TeacherUser);
    if (isTeacherUser && me?.teacher_profile) {
      setIsTeacherUser(true);
      setValue({
        class_teachers: [{ teacher: { ...me.teacher_profile, isFixed: true } }],
        is_classe: true,
      });
    }
  }, [roles]);

  useEffect(() => {
    if (me?.teacher_profile?.teacher_levels) {
      setValue({
        ...value,
        class_levels: me.teacher_profile.teacher_levels,
      });
    }
  }, [me]);

  return (
    <ScrollContainer>
      <LoadingOverlay isOpen={isLoading} />
      <h1>Create Event</h1>
      <Box className="inputs-container">
        <ClassView
          value={value}
          onNewImageSet={(imageAsBase64: string) => {
            setImageSource(imageAsBase64);
          }}
          onClassUpdated={(newValue: Partial<Class>) => {
            setValue({
              ...value,
              ...newValue,
            });
          }}
          addFormError={(key: string, message: string) => {
            setFormErrors([...formErrors, { key, message }]);
          }}
          removeFormError={(key: string) => {
            const index = formErrors.findIndex((error) => error.key === key);
            if (index > -1) {
              formErrors.splice(index, 1);
              setFormErrors([...formErrors]);
            }
          }}
        ></ClassView>
        <Button
          variant="contained"
          onClick={async () => {
            try {
              if (!value?.image_url && !imageSource) {
                setGlobalErrorNotification('Set a picture');
                return;
              }
              if (!value?.name) {
                setGlobalErrorNotification('Set a name');
                return;
              }
              if (!value?.location_name) {
                setGlobalErrorNotification('Provide a location name');
                return;
              }
              if (!value?.description) {
                setGlobalErrorNotification('Set a description');
                return;
              }
              if (!value?.location) {
                setGlobalErrorNotification('Set a location');
                return;
              }
              if (formErrors.length > 0) {
                setGlobalErrorNotification(formErrors[0].message);
                return;
              }

              const variables = {
                ...value,
                class_levels: value?.class_levels?.map((classLevel) => {
                  return { level_id: classLevel.level.id };
                }),
                class_owners: value?.class_owners?.map((classOwner) => {
                  return {
                    teacher_id: classOwner.teacher.id,
                    is_payment_receiver: classOwner.is_payment_receiver,
                  };
                }),
                class_teachers: value?.class_teachers?.map((classTeacher) => {
                  return {
                    teacher_id: classTeacher.teacher.id,
                    is_owner: classTeacher.is_owner,
                  };
                }),
                class_booking_options: value?.class_booking_options
                  ?.filter(
                    (classBookingOption) =>
                      !classBookingOption.booking_option.wasMarkedForDeletion
                  )
                  .map((classBookingOption) => {
                    const bookingOption = classBookingOption.booking_option;
                    return {
                      booking_option: {
                        data: {
                          id: bookingOption.id,
                          title: bookingOption.title,
                          subtitle: bookingOption.subtitle,
                          price: bookingOption.price,
                          currency: bookingOption.currency,
                          discount: bookingOption.discount,
                        },
                      },
                    };
                  }),
                recurring_patterns: value?.recurring_patterns?.map(
                  (recurringPattern) => {
                    delete recurringPattern.wasUpdated;
                    return {
                      ...recurringPattern,
                      start_time: recurringPattern.start_time.toISOTime(),
                      end_time: recurringPattern.end_time.toISOTime(),
                    };
                  }
                ),
              };

              if (imageSource && FileUtils.isBase64(imageSource)) {
                setIsImageUploadLoading(true);
                const imageAsBlob = await FileUtils.convertBase64ToBlob(
                  imageSource
                );
                const uploadedImageUrl = await Firebase.uploadFile(imageAsBlob);
                setIsImageUploadLoading(false);

                variables.image_url = uploadedImageUrl;
              } else {
                variables.image_url = imageSource;
              }

              const insertResult = await mutateFunction({
                variables: variables,
              });

              if (
                value &&
                !value.id &&
                value?.invites?.length &&
                value?.invites?.length > 0
              ) {
                // Class is created
                const id = insertResult.data?.insert_classes_one.id;
                for (const invite of value.invites) {
                  await inviteMutation({
                    variables: {
                      ...(invite?.email && { email: invite.email }),
                      ...(invite.invited_user?.id && {
                        userId: invite.invited_user?.id,
                      }),
                      entityId: id,
                      entityType: invite.entity,
                    },
                  });
                  setGlobalSuccessNotification(
                    `Invited ${
                      invite?.email ??
                      invite.invited_user?.teacher_profile?.name
                    }`
                  );
                }
              }
            } catch (e) {
              console.error(e);
              setGlobalErrorNotification(JSON.stringify(e));
            }
          }}
        >
          Create
        </Button>
      </Box>
    </ScrollContainer>
  );
};
